// src/pages/preview.js

import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { usePrismicPreview } from "gatsby-source-prismic"

const PreviewPage = ({ location }) => {
  const { previewData, path } = usePrismicPreview(location, {
    repositoryName: "bluplanet",
  })

  const getPath = doc => {
    if (doc.type === "bin") {
      return "/bins/"
    }
    if (doc.type === "service") {
      return "/services/" + doc.slugs[0] + "/"
    }
    if (doc.type === "area_of_operation") {
      return "/areas/" + doc.slugs[0] + "/"
    }
    if (doc.type === "home") {
      return "/"
    }
    if (doc.type === "blog_post") {
      return "/blog/" + doc.uid + "/"
    }
    return "/" + doc.slugs[0] + "/"
  }

  useEffect(() => {
    if (previewData) {
      window.__PRISMIC_PREVIEW_DATA__ = previewData

      navigate(getPath(previewData[Object.keys(previewData)[0]]))
    }
  }, [previewData, path])

  return <div>Loading preview...</div>
}

export default PreviewPage
